<template>
  <v-skeleton-loader
      id="ResourceCardPlaceholder"
      min-height="180"
      type="article"
      color="primary">

  </v-skeleton-loader>

</template>

<script>
/**
 * ResourceCardPlaceholder.vue is a placeholder card for the resoureceCard.vue
 *
 * @summary placeholder card for a resource
 * @author Dominik Haas-Artho
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

export default {
  name: 'ResourceCardPlaceholder',
  components: {},
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
